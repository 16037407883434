<template>
    <a-row :gutter="25">
      <div class="card w-100">
        <FormValidationWrap>
        <VerticalFormStyleWrap>
          <a-form
            v-if="!eventForm.hideFormCreateChildrenEvent || !childrenEvents.length"
            name="sDash_validation-form"
            ref="formRef"
            :model="formState"
            :rules="rules"
            :layout="dataDOM.layout"
          >
            <a-row :gutter="25">
              <a-col :xxl="12" :lg="12" :md="24" :xs="24">
                <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                  <h3 class="form-font title">¿A quién va dirigido el evento?</h3>
                </a-col>
                <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                  <a-form-item ref="administrativos" name="administrativos" class="form-font mx-3">
                    <a-checkbox class="check-event"
                      v-on:change="onChangeAimedAt('admin_can_register')"
                      v-model:checked="formState.administrativos">
                      Personal en centro de trabajo administrativo
                    </a-checkbox>
                  </a-form-item>
                </a-col>
                <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                  <a-form-item ref="educativos" name="educativos" class="form-font mx-3">
                    <a-checkbox class="check-event" v-on:change="onChangeAimedAt('edu_can_register')"
                      v-model:checked="formState.educativos"
                    >
                      Estructura educativa
                    </a-checkbox>
                  </a-form-item>
                </a-col>
                <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                  <a-form-item ref="alumnos" name="alumnos" class="form-font mx-3">
                    <a-checkbox
                      class="check-event"
                      v-model:checked="formState.alumnos"
                      v-on:change="onChangeAimedAt('student_can_register')"
                      :disabled="formState.administrative_area_ids?.length">
                      Estudiantes
                    </a-checkbox>
                  </a-form-item>
                </a-col>
                <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                  <a-form-item ref="ciudadanos" name="ciudadanos" class="form-font mx-3">
                    <a-checkbox
                      class="check-event"
                      v-model:checked="formState.ciudadanos"
                      v-on:change="onChangeAimedAt('citizen_can_register')"
                      :disabled="formState.administrative_area_ids?.length || formState.work_center_ids?.length ? true : false">
                      Ciudadanos
                    </a-checkbox>
                  </a-form-item>
                </a-col>
                <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-if="dataDOM.showMessageError">
                  <span class="title-normal" style="color:red;">Este campo es requerido</span>
                </a-col>
              </a-col>
              <a-col :xxl="12" :lg="12" :md="24" :xs="24">
                <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                  <a-form-item
                    ref="administrative_area_ids"
                    :name="formState.ciudadanos || formState.educativos || formState.alumnos ? '' : 'administrative_area_ids'"
                    :label="formState.ciudadanos ||formState.educativos || formState.alumnos ? 'Áreas administrativas que participan' : 'Áreas administrativas que participan *'"
                    class="form-font mx-3">
                    <a-select
                      name="administrative_area_ids "
                      class="title-normal"
                      mode="multiple"
                      style="width: 100%"
                      placeholder="Seleccione opciones"
                      @change="handleChangeMultiSelect($event, 'administrative_area_ids')"
                      v-model:value="formState.administrative_area_ids"
                      :disabled="formState.ciudadanos || formState.alumnos"
                      :optionFilterProp="'children'"
                      >
                        <a-select-option class="title-normal" :value="0">
                          Todas las áreas/centros de trabajo pueden participar
                        </a-select-option>
                        <a-select-option class="title-normal" v-for="area in dataDOM.administrativesAreas" :key="area.id_arbol" :value="area.id_arbol">
                          {{ area.nombre }}
                        </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                  <a-form-item
                    ref="work_center_ids"
                    :name="formState.ciudadanos || formState.administrativos ? '' : 'work_center_ids'"
                    :label="formState.ciudadanos || formState.administrativos ? 'Centros de trabajo que participan' : 'Centros de trabajo que participan *'"
                    class="form-font mx-3">
                    <a-select
                      class="title-normal"
                      name="work_center_ids "
                      mode="multiple"
                      style="width: 100%"
                      placeholder="Seleccione opciones"
                      @change="handleChangeMultiSelect($event, 'work_center_ids')"
                      v-model:value="formState.work_center_ids"
                      :disabled="formState.ciudadanos"
                      :optionFilterProp="'children'"
                      >
                        <a-select-option class="title-normal" :value="0">
                          Todas las áreas/centros de trabajo pueden participar
                        </a-select-option>
                        <a-select-option class="title-normal" v-for="workCenter in dataDOM.workCenters"  :key="workCenter.id" :value="workCenter.id">
                          {{ workCenter.nombre }}
                        </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-col>
            </a-row>
            <a-row :gutter="30">
                <a-col :md="12" :xs="24">
                  <p class="title-normal m-0 mt-20">¿Solo el Director de CT puede registrar?</p>
                  <a-form-item ref="director_register" name="director_register" class="form-font pt-0 mx-3">
                      <a-radio-group v-model:value="eventForm.registration.director_register" >
                      <a-radio class="form-font" :value="1"> Si </a-radio>
                      <a-radio class="form-font" :value="0"> No </a-radio>
                      </a-radio-group>
                  </a-form-item>
                </a-col>
                <a-col :md="12" :xs="24" 
                v-if="!(eventForm.edu_can_register && eventForm.student_can_register)
                      && !eventForm.student_can_register
                      || eventForm.citizen_can_register">
                  <p class="title-normal m-0 mt-20">¿Solo el Administrador de Área puede registrar?</p>
                  <a-form-item ref="only_admin_register" name="only_admin_register" class="form-font pt-0 mx-3">
                      <a-radio-group v-model:value="eventForm.registration.only_admin_register" >
                      <a-radio class="form-font" :value="1"> Si </a-radio>
                      <a-radio class="form-font" :value="0"> No </a-radio>
                      </a-radio-group>
                  </a-form-item>
                </a-col>
                <a-col :md="12" :xs="24">
                  <p class="title-normal m-0 mt-20">¿El evento tiene actividades?</p>
                  <a-form-item ref="activities" name="activities" class="form-font pt-0 mx-3">
                      <a-radio-group v-model:value="eventForm.activities" >
                      <a-radio class="form-font" :value="true"> Si </a-radio>
                      <a-radio class="form-font" :value="false"> No </a-radio>
                      </a-radio-group>
                  </a-form-item>
                </a-col>
                <a-col :md="12" :xs="24">
                  <a-form-item ref="maximum_assistance" name="maximum_assistance" label="Cantidad máxima de participantes" class="form-font mx-3">
                    <a-input type="number" v-model:value="eventForm.maximum_assistance" placeholder="" style="border-radius: 30px"/>
                  </a-form-item>
                </a-col>
            </a-row>
            <FormMaker
              v-if="eventForm.activities && !eventForm.hideFormCreateChildrenEvent"
              :eventForm="eventForm"
              :sendForm="submitStatus.dynamicForm"
              v-on:getFormValues="checkHandleSubmitForms"
            />
            <a-row>
              <a-col :md="24" :xs="24">
                  <button class="btn-fill-add-red" v-on:click="saveDataNewEvent" v-if='!eventForm.isFormOnEdit'>
                    <span class="title-normal"> Guardar evento </span>
                  </button>
                  <button class="btn-fill-add-red" v-on:click="saveDataEditEvent" v-else>
                    <span class="title-normal"> Guardar cambios </span>
                  </button>
                  <button class="btn-outlined-exit" v-if="childrenEvents.length" v-on:click="handleFormEventCancel">
                    <span class="title-normal">Cancelar</span>
                  </button>
              </a-col>
            </a-row>
          </a-form>
        </VerticalFormStyleWrap>
        </FormValidationWrap>
        <DetailChildrenEvent
          v-if="dataDOM.childrenEvents.length"
          :events="dataDOM.childrenEvents"
          :showTitle="eventForm.hideFormCreateChildrenEvent"
          :workCenters="dataDOM.workCenters"
          :isEdit='isEdit'
          @delete:subEvent="deleteEventRegister($event)"
          @update:subEvent="editEventRegister($event)"
        />
        <a-row :gutter="30" v-if="dataDOM.childrenEvents.length">
          <a-col :md="24" :xs="24" style="text-align: center;">
            <button type="button" class="btn-outlined-add btn-not-border" v-on:click="handleAddAnotherChildEvent">
              <img :src="require(`@/static/img_events/SEJ_Eventos_agregar.svg`)" alt="logo" width="17" />
              <span class="title-normal ml-1"> Agregar otro evento </span>
            </button>
          </a-col>
        </a-row>
      </div>
    </a-row>
</template>
<script>

import { reactive, ref, defineAsyncComponent } from 'vue';
import { getAdministrativeAreas, getWorkCenter } from '../../../../services/events';
import { Notification } from 'ant-design-vue';
import * as questionTypes from '@/constants/QuestionTypes.js';
import {generateUUID} from '../../../../utility/utility.js';
import {cctFieldsDic} from '@/constants/CCTFieldsDic.js';

const DetailChildrenEvent = defineAsyncComponent(() => import('./DetailChildrenEvents.vue'))
const FormMaker = defineAsyncComponent(() => import('./DynamicForm/FormMaker/FormMaker.vue'))

const EventAimedAt = {
  name: 'EventAimedAtSubEvent',
  components: {
    DetailChildrenEvent,
    FormMaker
  },
  props: {
    eventForm: Object,
    childrenEvents: [],
    sendForm: Boolean,
    isEdit: Boolean,
    formResponseParent: Object,
    isFormOnEdit: Boolean
  },
  watch: {
    formResponseParent: function (newValue) {
      this.checkHandleSubmitForms(newValue);
    },
    isFormOnEdit: function (newValue){
      if(newValue){
        if(this.isFormOnEdit){
          this.formState.administrativos = Number.isInteger(this.eventForm.admin_can_register) ? Boolean(this.eventForm.admin_can_register) :this.eventForm.admin_can_register
          this.formState.educativos =  Number.isInteger(this.eventForm.edu_can_register) ? Boolean(this.eventForm.edu_can_register) :this.eventForm.edu_can_register
          this.formState.alumnos = Number.isInteger(this.eventForm.student_can_register) ? Boolean(this.eventForm.student_can_register) :this.eventForm.student_can_register
          this.formState.ciudadanos = Number.isInteger(this.eventForm.citizen_can_register) ? Boolean(this.eventForm.citizen_can_register) :this.eventForm.citizen_can_register
          this.formState.administrative_area_ids = this.eventForm.all_areas ? 0 : this.eventForm.administrative_area_ids || []
          this.formState.work_center_ids = this.eventForm.all_work_areas ? 0 : this.eventForm.work_center_ids || []
        }
      }
    }
  },
  emits: ['getFormValues', 'saveDataNewEvent', 'hideFormsCreate', 'delete:subEvent', 'delete:subEventWithOutAsk', 'update:subEvent'],
  methods:{
    deleteEventRegister(indexForm){
      this.$emit('delete:subEvent', indexForm);
    },
    editEventRegister(event){
      this.$emit('update:subEvent', event);
      this.$emit('delete:subEventWithOutAsk', event.indexToEdit);
    }
  },
  mounted() {
    if(this.isEdit){
      this.formState.administrativos = this.eventForm.admin_can_register === 1 ? true: false
      this.formState.educativos = this.eventForm.edu_can_register === 1 ? true: false
      this.formState.alumnos = this.eventForm.student_can_register=== 1 ? true: false
      this.formState.ciudadanos = this.eventForm.citizen_can_register=== 1 ? true: false
      this.formState.administrative_area_ids = this.eventForm.all_areas ? 0 : this.eventForm.administrative_area_ids || [];
      this.formState.work_center_ids = this.eventForm.all_work_areas ? 0 : this.eventForm.work_center_ids || [];
    }
  },
  async setup(props, {emit}) {
    const formRef = ref();

    const rowID = `R${generateUUID()}`
    const colID = `C${generateUUID()}`

    const getAdministrativeAreasFunc = async () => {
      var areas = [];
      await getAdministrativeAreas().then((response) => {
        if(response.success){
          areas = response.data;
        }
      })
      .catch(() => {
        //console.log(error)
      })
      return areas;
    }

    const getWorkCenterFunc = async () => {
      var workCenter = [];
      await getWorkCenter().then((response) => {
        if(response.success){
          workCenter = response.data;
        }
      })
      .catch(() => {
        //console.log(error)
      })
      return workCenter;
    }

    const dataDOM = reactive({
      layout: "vertical",
      administrativesAreas: await getAdministrativeAreasFunc(),
      workCenters: await getWorkCenterFunc(),
      showMessageError: false,
      childrenEvents: props.childrenEvents
    })

    const formState = reactive({
      administrativos: false,
      educativos : false,
      alumnos: false,
      ciudadanos: false,
      administrative_area_ids: [],
      work_center_ids: [],
    });




    const submitStatus = reactive({
      loading: false,
      aimedAt: false,
      generalInfo: false,
      sedes: false,
      dynamicForm: false,
    });

    const formStatus = reactive({
      generalInfo: undefined,
      aimedAt: undefined,
      sedes: undefined,
      dynamicForm: undefined,
    });

    const saveDataNewEvent = () => {
      formStatus.generalInfo = undefined;
      formStatus.aimedAt = undefined;
      formStatus.sedes = undefined;
      formStatus.dynamicForm = props.eventForm.activities ? undefined : true;
      submitStatus.generalInfo = !submitStatus.generalInfo;
      submitStatus.aimedAt = !submitStatus.aimedAt;
      submitStatus.sedes = !submitStatus.sedes;
      submitStatus.dynamicForm = !submitStatus.dynamicForm;
      emit('getFormValues');
      handleSendFormEvent();
    };
    const saveDataEditEvent = ()=>{
      saveDataNewEvent()
    }
    const rules = {
      administrativos: [
        {
          required: false,
          trigger: 'blur',
          type: 'boolean',
        },
      ],
      educativos: [
        {
          required: false,
          trigger: 'blur',
          type: 'boolean',
        },
      ],
      ciudadanos: [
        {
          required: false,
          trigger: 'blur',
          type: 'boolean',
        },
      ],
      alumnos: [
        {
          required: false,
          trigger: 'blur',
          type: 'boolean',
        },
      ],
      administrative_area_ids: [
        {
          required: props.eventForm.generalInfo.type == 'benefits' ||
            props.eventForm.generalInfo.type == 'other' ? false : true,
          trigger: 'blur',
          type: 'array',
          message: 'Este campo es requerido',
        },
      ],
      work_center_ids: [
        {
          required:  props.eventForm.generalInfo.type == 'benefits' ||
            props.eventForm.generalInfo.type == 'other' ? false : true,
          trigger: 'blur',
          type: 'array',
          message: 'Este campo es requerido',
        },
      ]
    };

    const onChangeAimedAt = (field) => {
      switch (field) {
        case "admin_can_register":
          props.eventForm.admin_can_register = !props.eventForm.admin_can_register;
          if (formState.administrativos) {
            formState.administrative_area_ids = [0];
            handleChangeMultiSelect(formState.administrative_area_ids, 'administrative_area_ids')
            formState.work_center_ids = [0];
            handleChangeMultiSelect(formState.work_center_ids, 'work_center_ids')
            props.eventForm.student_can_register = false;
            formState.alumnos = false;
            props.eventForm.citizen_can_register = false;
            formState.ciudadanos = false;
          } else {
            const index_administrative_area_ids = formState.administrative_area_ids.findIndex(element => element === 0);
            formState.administrative_area_ids.splice(index_administrative_area_ids, 1)
            handleChangeMultiSelect(formState.administrative_area_ids, 'administrative_area_ids')
            const index_work_center_ids = formState.work_center_ids.findIndex(element => element === 0);
            formState.work_center_ids.splice(index_work_center_ids, 1)
            handleChangeMultiSelect(formState.work_center_ids, 'work_center_ids')
          }
          props.eventForm.registration.director_register = 0;
          props.eventForm.registration.admin_area_register = 0;
        break;
        case "edu_can_register":
          props.eventForm.edu_can_register = !props.eventForm.edu_can_register;
          props.eventForm.registration.director_register = 0;
          props.eventForm.registration.admin_area_register = 0;
        break;
        case "student_can_register":
          props.eventForm.student_can_register = !props.eventForm.student_can_register;
          props.eventForm.registration.director_register = 0;
          props.eventForm.registration.admin_area_register = 0;
        break;
        case "citizen_can_register":
          props.eventForm.citizen_can_register = !props.eventForm.citizen_can_register;
          props.eventForm.registration.director_register = 0;
          props.eventForm.registration.admin_area_register = 0;
        break;
        default:
        break;
      }
    }

    const handleChangeMultiSelect = (value, select) => {
      switch (select) {
        case "administrative_area_ids":
          if(value.includes(0)){
            value  = dataDOM.administrativesAreas.map(area => area.id_arbol)
            props.eventForm.all_areas = true;
          } else {
            props.eventForm.all_areas = false;
          }
          props.eventForm.administrative_area_ids = value;

        break;
        case "work_center_ids":
          if(value.includes(0)){
            value  = dataDOM.workCenters.map(workCenter => workCenter.id)
            props.eventForm.all_work_areas = true;
          } else {
            props.eventForm.all_work_areas = false;
          }
          props.eventForm.work_center_ids = value;

        break;
        default:
        break;
      }
    }

    const checkHandleSubmitForms = (values) => {

      let thereIsError = null;
      let finishedValidate = null;
      if(values?.typeForm != null) {
        if(!props.eventForm.activities){
          formStatus['dynamicForm'] = true;
        }
        formStatus[values.typeForm] = values.is_ok;
        thereIsError = Object.values(formStatus).includes(false);
        finishedValidate = !Object.values(formStatus).includes(undefined);
        if (thereIsError && finishedValidate) {
          Notification['error']({
            message: 'Error',
            description: 'Complete el formulario',
          });
        }
        if (!thereIsError && finishedValidate){
          const data = {
            creator_id: 1,
            admin_can_register: props.eventForm.admin_can_register,
            edu_can_register: props.eventForm.edu_can_register,
            student_can_register: props.eventForm.student_can_register,
            citizen_can_register: props.eventForm.citizen_can_register,
            all_areas: props.eventForm.all_areas,
            all_work_areas: props.eventForm.all_work_areas,
            administrative_area_ids: props.eventForm.administrative_area_ids,
            work_center_ids: props.eventForm.work_center_ids,
            ...props.eventForm.generalInfo,
            ...props.eventForm.registration,
            activities: props.eventForm.activities,
            headquarters: props.eventForm.sedes,
            questions_json: [],
            maximum_assistance: props.eventForm.maximum_assistance,
            headquartersDeleted: props.eventForm.headquartersDeleted || []
          };
          data.questions_json = validateCCTQuestion(props.eventForm.dynamicForm || {})
          data.questions_json.title = data.questions_json.sections[0].title
          data.questions_json.description = data.questions_json.sections[0].description

          if(props.eventForm.id){
            data['id'] = props.eventForm.id
          }
          emit('saveDataNewEvent', data);
          emit('hideFormsCreate', true);
          clearFields();
        }
      }
    }

    const validateCCTQuestion = (newForm) => {

      const form = JSON.parse(JSON.stringify(newForm))

      form.sections.forEach(section => {
        section.questions.forEach((question, idx) => {
          if (question.type === questionTypes.cct) {
            const previousQuestions = [];
            for(let i = idx+1; i < section.questions.length; i++) {
              if (section.questions[i]?.type !== questionTypes.cct_fill) break;
              previousQuestions.push(section.questions[i]);
            }
            section.questions.splice(idx+1, previousQuestions.length); //Delete level 3 questions readOnly
            const newQuestions = question.cct.map((value) => {
              //Add previous question if exists, else create it
              return previousQuestions.find(question => question.cct_field === value) || addCCTQuestion(value);
            });
            section.questions.splice(idx + 1, 0, ...newQuestions);
          }
        });
      });

      return form
    }

    const addCCTQuestion = (cct) => {
      return {
        id: `Q${generateUUID()}`,
        type: questionTypes.cct_fill,
        title: cctFieldsDic.find(option => option.value === cct)?.label,
        cct_field: cct,
        uuid: "",
        validations: {
          dataType: "string",
          required: false
        },
        description: "",
        matrixValidations: {},
        hasDescription: false,
        hasValidations: true,
        isLevel2: false,
        options: [
          { id: `O${generateUUID()}`, content: '', label: 'Opción 1', goto: 'next' },
        ],
        matrixOptions: {
          rows: [
            { id: `R${generateUUID()}`, content: '' },
          ],
          cols: [
            { id: `C${generateUUID()}`, content: '' },
          ]
        },
        goto: false
      }
    }

    const handleSendFormEvent = () => {
      formRef.value
        .validate()
        .then(() => {
          if(validateCheckBoxAimetAt()){
            dataDOM.visible = true;
            dataDOM.showMessageError = false;
            checkHandleSubmitForms({ is_ok: true, typeForm: 'aimedAt' });
          } else {
            dataDOM.showMessageError = true;
            checkHandleSubmitForms({ is_ok: false, typeForm: 'aimedAt' });
          }
        })
        .catch(() => {
          //console.log(e);
          if(validateCheckBoxAimetAt()){
            dataDOM.showMessageError = false;
          } else {
            dataDOM.showMessageError = true;
          }
          checkHandleSubmitForms({ is_ok: false, typeForm: 'aimedAt' });
        });
    }

    const validateCheckBoxAimetAt = () => {
      return props.eventForm.admin_can_register || 
         props.eventForm.edu_can_register ||
         props.eventForm.student_can_register ||
         props.eventForm.citizen_can_register;
    }

    const handleAddAnotherChildEvent = () => {
      formState.administrativos = false
      formState.educativos = false
      formState.alumnos = false
      formState.ciudadanos = false
      formState.administrative_area_ids = [];
      formState.work_center_ids = [];
      emit('hideFormsCreate', false);
      window.scroll({ top: 850, behavior: 'smooth' });
    }

    const clearFields = () => {
        props.eventForm.id = null;
        props.eventForm.admin_can_register = false;
        props.eventForm.edu_can_register = false;
        props.eventForm.student_can_register = false;
        props.eventForm.citizen_can_register = false;
        props.eventForm.all_areas = false;
        props.eventForm.all_work_areas = false;
        props.eventForm.administrative_area_ids = [];
        props.eventForm.work_center_ids = [];
        props.eventForm.activities = false;
        props.eventForm.maximum_assistance = null;
        props.eventForm.generalInfo.title = '';
        props.eventForm.generalInfo.event_start_date = '';
        props.eventForm.generalInfo.event_end_date = '';
        props.eventForm.generalInfo.event_start_hour = '';
        props.eventForm.generalInfo.event_end_hour = '';
        props.eventForm.generalInfo.start_register_date = '';
        props.eventForm.generalInfo.end_register_date = '';
        props.eventForm.sedes = [];
        props.eventForm.registration.director_register = 0;
        props.eventForm.registration.only_admin_register = 0;
        props.eventForm.registration.responsible_id = null;
        props.eventForm.registration.info_visible = 1;
        props.eventForm.registration.email = '';
        props.eventForm.registration.telephone = '';
        props.eventForm.registration.assistants = false;
        props.eventForm.registration.attendance_record_responsible_id = null;
        props.eventForm.dynamicForm = getDynamicFormClear();
        props.eventForm.headquartersDeleted = [];
        formState.educativos = false;
        formState.alumnos = false;
        formState.ciudadanos = false;
        formState.administrative_area_ids = []
        formState.work_center_ids = []
    }

    const getDynamicFormClear = () => {
      return {
            updatedQuestions: [],
            sections: [
            {
                id: `S${generateUUID()}`,
                title: '',
                description: '',
                questions: [
                {
                    id: `Q${generateUUID()}`,
                    uuid: '',
                    validations: {
                    required: false,
                    dataType: 'string',
                    characterType: 'text',
                    validationType: 'limits',
                    },
                    maxFileSize: 10,
                    maxFiles: 1,
                    hasDescription: false,
                    hasValidations: true,
                    title: '',
                    description: '',
                    type: questionTypes.shortAnswer,
                    options: [
                    { id: `O${generateUUID()}`, content: '', label: '' },
                    ],
                    matrixOptions: {
                    rows: [
                        { id: rowID, content: '' },
                    ],
                    cols: [
                        { id: colID, content: '' },
                    ],
                    },
                    matrixValidations: {
                    [`T_${colID}_${rowID}`] :{
                        dataType: 'text',
                        validationType: 'limits',
                        min: '',
                        max: '',
                        format: 'email',
                    }
                    },
                    layout: 'vertical',

                },
                ],
            },

            ],
            layout: 'vertical'
        }
    }

    const handleFormEventCancel = () => {
      emit('hideFormsCreate', true);
    }

    return {
      handleSendFormEvent,
      formRef,
      formState,
      rules,
      dataDOM,
      onChangeAimedAt,
      handleChangeMultiSelect,
      checkHandleSubmitForms,
      saveDataNewEvent,
      submitStatus,
      handleAddAnotherChildEvent,
      handleFormEventCancel,
      saveDataEditEvent
    };
  },
};

export default EventAimedAt;
</script>
<style scoped lang="sass">
.card
  background-color: #fff !important
  // border-radius: 15px
  padding: 1rem
  border-top: 0.5px solid #bdbdbd

.form-font
  font-family: Nutmeg
  &.title
    font-weight: bold
    padding: 0
.btn-fill-add
  border: 1px solid #3FA7E1
  color: #ffffff
  padding: 5px 15px 5px 15px
  background: #3FA7E1
  border-radius: 24px
.btn-fill-add-red
  border: 1px solid #e70e4c
  color: #ffffff
  padding: 5px 15px 5px 15px
  background: #e70e4c
  border-radius: 24px
  margin: 0
.btn-disabled:hover
  cursor: not-allowed !important
button:hover
  cursor: pointer
.btn-outlined-exit
  border: 1px solid #636668
  color: #636668
  padding: 5px 15px 5px 15px
  background: none
  border-radius: 24px
  margin-left: 2rem
.ant-checkbox-checked 
  background-color: #5F63F2 !important
  border-color: #5F63F2 !important
.ant-checkbox-inner
  background-color: #5F63F2 !important
  border-color: #5F63F2 !important
.card-sedes
  background-color: #ffff
  box-shadow: 0px 3px 6px #00000029
  border-radius: 20px
  padding: 1.5rem
  margin: 1rem
.bt-option-card
  width: 100%
  color: #212529
  background: none
  border: none
  padding-left: 15px !important
  padding: 5px
  font-size: 12px
  margin-bottom: 0.5rem
.bt-option-card:hover
  cursor: pointer
  background: #f1efef
.btn-not-border
  border: none !important
.btn-outlined-add
  border: 1px solid #e70e4c
  color: #e70e4c
  padding: 5px 15px 5px 15px
  background: none
  border-radius: 7px
</style>
<style>
.ant-checkbox-checked{
  background-color: #3FA7E1;
  border-color: #3FA7E1;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #3FA7E1;
  border-color: #3FA7E1;
}
</style>
